import { Component, HostBinding, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';
import { Logger } from '@shared/services/logger.service';
import { Select, Store } from '@ngxs/store';
import { Settings } from 'luxon';
import { SetupStatuses } from '@data/tyres/tyres.actions';
import { AuthState } from '@data/auth/auth-state';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { SvgIconRegistry } from '@app/@wchfs-ui';
import { ProfileState } from '@data/profile/profile-state';
import { ProfileStateModel } from '@data/profile/profile.actions';
import { UpdateService } from '@shared/services/update.service';
import { Roles } from '@core/Roles';
import { SurveyService } from '@data/survey/survey.service';
import { SurveyStatus, SurveyStatusValue } from '@data/survey/survey.model';
import { EnumResource } from '@shared/interfaces/enum-resource';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [UpdateService],
})
export class AppComponent implements OnInit {
  @Select(AuthState.isAuthenticated)
  isAuthenticated$: Observable<boolean>;
  @Select(ProfileState.impersonatedBy)
  impersonatedBy$: Observable<ProfileStateModel>;
  @Select(ProfileState.routeRolePrefix)
  routeRolePrefix$: Observable<any>;
  @Select(ProfileState.surveyStatus)
  surveyStatus$: Observable<EnumResource<SurveyStatusValue>>;
  @HostBinding('style.padding-top') get paddingTop(): string {
    return this.store.selectSnapshot(ProfileState.impersonatedBy)
      ? this.impersonatingAsWidgetHeightInPx + 'px'
      : undefined;
  }
  assetUrl = environment.assetUrl;
  defaultLanguage = environment.defaultLanguage;
  impersonatingAsWidgetHeightInPx = 45;
  surveyWidgetHeightInPx = 45;
  Roles = Roles;
  protected readonly SurveyStatus = SurveyStatus;

  constructor(
    private translate: TranslateService,
    private svgIconRegistry: SvgIconRegistry,
    private domSanitizer: DomSanitizer,
    private store: Store,
    private updateService: UpdateService,
    protected readonly surveyService: SurveyService
  ) {}

  ngOnInit(): void {
    this.setupIcons();
    this.setupLogger();
    this.setupUsersnap();
    this.setupHotjar();
    this.setApplicationLanguage();
    this.onFirstLoginActions();
    this.updateService.checkForUpdates();
  }

  private setupIcons(): void {
    this.svgIconRegistry.addSvgIconSetInNamespace(
      'wchfs',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${this.assetUrl}/svg-defs.svg`)
    );
    this.svgIconRegistry.addSvgIconSetInNamespace(
      'pso-legacy',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${this.assetUrl}/pso-svg-defs-legacy.svg`)
    );
    this.svgIconRegistry.addSvgIconSetInNamespace(
      'pso',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${this.assetUrl}/pso-svg-defs.svg`)
    );
  }

  private setupLogger(): void {
    if (environment.production) {
      Logger.enableProductionMode();
    }
  }

  private setupUsersnap(): void {
    // TODO: Verify UserSnap
    if (environment.userSnap) {
      // AppComponent.enableScript(environment.userSnap);
    }
  }

  private setupHotjar(): void {
    if (environment.hotjar) {
      const s = document.createElement('script');
      s.innerHTML = `(function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:3562628,hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
      document.head.appendChild(s);
    }
  }

  private static enableScript(src: string): void {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = src;
    const x = document.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);
  }

  private setApplicationLanguage(): void {
    this.translate.setDefaultLang(this.defaultLanguage);
    Settings.defaultLocale = this.defaultLanguage;
  }

  private onFirstLoginActions() {
    this.isAuthenticated$
      .pipe(
        filter((isAuthenticated) => isAuthenticated),
        take(1)
      )
      .subscribe((isAuthenticated: boolean) => {
        this.store.dispatch(new SetupStatuses());
      });
  }
}
